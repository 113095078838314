@import 'src/styles/variables';

.ruler {
  border-bottom: 1px solid $gray-10;
}

.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

.addMargin {
  margin-top: 30px;
}

.section {
  padding: 100px 20px;
  margin: auto;
  max-width: $content-width;

  @include for-size(phone-only) {
    padding: 50px 20px;
  }
}

.medium {
  max-width: 800px;
}

.innerContainerSection {
  padding: 50px 20px;

  @include for-size(phone-only) {
    padding: 50px 10px;
  }
}

.container {
  width: 100%;

  &.splitContainer {
    display: flex;

    @include for-size(phone-only) {
      flex-direction: column;
    }
  }
}

.containerCircle {
  background-color: $blue-5;
  position: relative;
}

.containerLightblue {
  background-color: $blue-5;
}

.containerDarkblue {
  // background-image: url('/images/hero/talent.webp');
  background: radial-gradient(circle at 100% 50%, $dark-blue, #133e94 50%);
  background-color: $darkblue;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  position: relative;
  overflow: hidden;
  z-index: 1;

  // :global(.no-webp) & {
  //   background-image: url('/images/hero/talent.jpg');
  // }

  :global {
    // TODO: for the meantime added a global block here because this class was used inside the section
    .type__subheadline {
      color: $white;
    }
  }

  .waveContainer {
    position: absolute;
    z-index: -1;
    width: 100%;
    bottom: 0;
    left: 0;

    @include ie-only {
      display: none;
    }
  }

  .wave {
    opacity: 0.2;
    color: $dark-blue-shadow;
    bottom: 0;
  }
}

.containerEnterprise {
  background: $blue-5;
  position: relative;
  z-index: 1;
  // height: 200px;
  &:after {
    content: '';
    background: $enterprise-gradient;
    top: 50%;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: -1;
    // background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1600 430' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.1 430L0 27.9C55.6 9.5 113.5.6 171.4.2c102.8-.8 203.2 22.7 299.3 54.5 3 1 5.9 2 8.9 3 183.6 62 365.7 146.1 562.4 192.1 186.7 43.7 376.3 34.4 557.9-12.6l.1 192.8H.1z' fill='%23fff' fillRule='nonzero'/%3E%3C/svg%3E");
  }

  .innerContainerSection {
    // padding-bottom: 0;

    max-width: $content-width;
    background: #1d2e3d;
    padding: 100px 75px;
    margin: auto;
    z-index: 0;
    position: relative;
    overflow: hidden;

    @include for-size(desktop-down) {
      padding: 40px;
    }
  }

  .gridContainer {
    margin-bottom: 0;
  }
}

.containerEnterpriseFull {
  background: #1d2e3d;
  position: relative;
  z-index: 1;

  .gridContainer {
    margin-bottom: 0;
  }
}

.containerPrimaryblue {
  background-image: url('/img/main.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $blue-100;

  :global(.no-webp) & {
    background-image: url('/img/main.png');
  }
}

.containerLightblueGradient {
  background: linear-gradient(180deg, $blue-5 0%, rgba(253, 254, 255, 1) 100%);
  /* w3c */
}

.containerPlainWhite {
  background-color: $white;
}

.leadgen {
  padding: 50px 20px;
  z-index: $z-base;

  @include for-size(phone-only) {
    width: 100%;
  }
}

.image {
  max-width: 600px;
  max-height: 600px;
}

.split {
  padding: 120px 40px;
  flex-basis: 50%;
  margin: auto;
  box-sizing: border-box;

  @include for-size(phone-only) {
    padding: 40px 30px;
    margin: inherit;
    flex-basis: auto; // workaround for ie
  }

  &:first-of-type {
    .splitContent {
      margin-left: auto;
    }
  }

  &:last-of-type {
    .splitContent {
      margint-right: auto;
    }
  }
}

.splitContent {
  max-width: 560px;
}
