@import 'src/styles/variables';

.stepItem {
  display: flex;
  margin-left: 50px;

  & ~ & {
    .stepContent {
      border-top: 1px solid $gray-10;
    }
  }

  .stepContentNoBorder {
    border: 0 none !important;
  }

  &:first-of-type {
    .stepTimeline:before {
      content: none;
    }
  }

  &:last-of-type {
    .stepTimeline:after {
      content: none;
    }
  }

  // @include for-size(tablet-landscape-down) {
  //   width: 320px;
  // }

  @include for-size(phone-only) {
    margin-left: 0px;
  }
}

$timeline-width: 3px;

.stepTimeline {
  display: flex;
  width: 48px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    margin-left: (5px - $timeline-width) / 2;
    border-left: $timeline-width solid $gray-10;
    height: 50%;
    top: 0;
    left: calc(50% - #{$timeline-width} / 2);
    position: absolute;
    z-index: $z-background;
  }

  &:after {
    content: '';
    margin-left: (5px - $timeline-width) / 2;
    border-left: $timeline-width solid $gray-10;
    height: 50%;
    bottom: 0;
    left: calc(50% - #{$timeline-width} / 2);
    position: absolute;
    z-index: $z-background;
  }
}

.stepOrder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 24px;
  background: $darkblue;
  color: $white;
  flex-shrink: 0;
  z-index: $z-base;
}

.stepContent {
  padding: 20px 0 20px 20px;

  &.stepContentEnterprise {
    min-height: 110px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  @include for-size(phone-only) {
    text-align: left;
  }
}

.stepHeadline {
  color: $darkblue;

  @include for-size(phone-only) {
    font-size: 18px;
  }
}

.stepDesc {
  margin-top: 10px;
  color: $black;
}

.list {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  width: 240px;

  @include for-size(tablet-landscape-down) {
    width: 320px;
  }

  @include for-size(phone-only) {
    width: 50%;
  }

  @include for-size(medium-phone-only) {
    width: 100%;
  }
}

.order {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 24px;
  background: $darkblue;
  color: $white;
}
