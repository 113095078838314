@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  // justify-content: center;
  justify-content: space-between;
  // margin: 10px auto;
}

.containerCenter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: center;
  justify-content: space-between;
  // margin: 10px auto;
  color: $white;
}

.reverse {
  @include for-size(tablet-portrait-down) {
    flex-direction: column-reverse;
  }
}

// NOTE: this are now remove because flex-basis and width are now computed dynamically according to the number of columns specified on Grid element
// .one {
//   .item {
//     flex-basis: 100%;
//     width: 100%;
//   }
// }

// .two {
//   .item {
//     flex-basis: 50%;
//     width: 50%;

//     @include for-size(phone-only) {
//       width: 100%;
//       flex-basis: 100%;
//       text-align: center;
//     }
//   }
// }

// .three {

//   .item {
//     flex-basis: 33.3333%;
//     width: 33.3333%;

//     @include for-size(phone-only) {
//       width: 100%;
//       flex-basis: 100%;
//     }
//   }

//   .itemDouble {
//     flex-basis: 66.6666%;
//     width: 66.6666%;

//     @include for-size(phone-only) {
//       width: 100%;
//       flex-basis: 100%;
//     }

//   }
// }

.item {
  @include for-size(phone-only) {
    width: 100% !important; // added important to ignore inline styles for width and flex-basis of this screen resolution
    flex-basis: 100% !important;
    text-align: center;
  }
}

.top {
  justify-content: flex-top;
}
